<template>
  <HsDropdownAvatar :name="user?.name" avatar-color="secondary">
    <div class="dropdown-item-text">
      <div class="d-flex align-items-center">
        <div class="avatar avatar-sm avatar-circle avatar-secondary">
          <span class="avatar-initials">{{ user?.name[0] }}</span>
        </div>
        <div class="flex-grow-1 ms-3">
          <h5 class="mb-0">{{ user?.name }}</h5>
          <p class="card-text text-body">{{ user?.email }}</p>
        </div>
      </div>
    </div>

    <div class="dropdown-divider"></div>

    <a class="dropdown-item" href="javascript:;" @click="logout()">{{ $t('menus.user.logout') }}</a>
  </HsDropdownAvatar>
</template>

<script lang="ts" setup>
  import { useAuthUser, useAuth } from '#imports';

  const user = useAuthUser();

  const { logout } = useAuth();
</script>
