<template>
  <header id="header" class="navbar navbar-expand-lg navbar-container headerWrapper bg-gray" style="z-index: 50">
    <nav class="navbar-nav-wrap">
      <!-- Logo -->
      <nuxt-link class="navbar-brand" :to="{ name: 'index' }" aria-label="Winx">
        <img src="/winx-square-yellow.svg" alt="Logo da Winx" style="height: 3.5rem" />
      </nuxt-link>
      <!-- End Logo -->
      <div class="col-divider divider">
        <img
          :src="$s3Shared(user?.company?.logo ?? '/companies/logos/logo.svg')"
          :alt="user?.company.name"
          :title="user?.company.name"
          style="max-width: 4.5rem; max-height: 4.5rem; width: 4.5rem"
        />
      </div>
      <!-- Secondary Content -->
      <div class="navbar-nav-wrap-secondary-content">
        <!-- Navbar -->
        <ul class="navbar-nav">
          <li v-if="user?.isSuper" class="nav-item">
            <!-- List Company Admin -->
            <button
              class="btn btn-ghost-secondary btn-icon rounded-circle"
              type="button"
              data-bs-toggle="offcanvas"
              data-bs-target="#offcanvasCompanies"
              aria-controls="offcanvasCompanies"
            >
              <img src="/svg/iconsax/buildings-2.svg" alt="Ícone da Lista de empresas" style="height: 1.5rem" />
            </button>
            <!-- End List Company Admin -->
          </li>

          <li class="nav-item">
            <!-- Account -->
            <LayoutHeaderUserMenu />
            <!-- End Account -->
          </li>
        </ul>
        <!-- End Navbar -->
      </div>
      <!-- End Secondary Content -->

      <!-- Toggler -->
      <button
        class="navbar-toggler border-0"
        type="button"
        data-bs-toggle="offcanvas"
        data-bs-target="#offcanvasMenu"
        aria-controls="offcanvasMenu"
      >
        <img src="/svg/iconsax/menu-1.svg" alt="Ícone de menu" style="height: 1.5rem" />
      </button>
      <!-- End Toggler -->

      <!-- Collapse -->
      <div id="navbarContainerNavDropdown" class="collapse navbar-collapse" style="margin-right: 1rem">
        <ul class="navbar-nav justify-content-end text-secondary gap-2">
          <li class="nav-item">
            <NuxtLink :to="{ name: 'index' }" class="nav-link ps-2" active-class="active">
              {{ $t('menus.header.home') }}
            </NuxtLink>
          </li>

          <li v-if="campaigns.length" class="nav-item">
            <NuxtLink
              :to="{ name: 'campaign', params: { campaign: campaigns[0].id } }"
              class="nav-link ps-2"
              active-class="active"
            >
              {{ $t('menus.header.surveys') }}
            </NuxtLink>
          </li>

          <li v-if="$user.value?.okrRole !== null" class="nav-item">
            <NuxtLink :to="currentPlan" class="nav-link ps-2" active-class="active"> Plano de Ação </NuxtLink>
          </li>
        </ul>
      </div>
      <!-- End Collapse -->
    </nav>
  </header>
</template>

<script lang="ts" setup>
  import { useAuthUser } from '#imports';
  import { useDiagnosisStore } from '~/stores/diagnosisStore';
  import { useOkrsStore } from '~/stores/okrsStore';

  const diagnosisStore = useDiagnosisStore();

  const campaigns = computed(() => diagnosisStore.campaigns);

  const okrsStore = useOkrsStore();

  const user = useAuthUser();
  const currentPlan = computed(() => okrsStore.currentPlan);
</script>

<style>
  .headerWrapper {
    position: fixed;
    width: 100%;
    background-color: #f5f5f5;
    box-shadow: 0px 12px 32px -12px rgba(0, 0, 0, 0.25);
  }
</style>
