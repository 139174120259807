<template>
  <div id="offcanvasMenu" class="offcanvas offcanvas-end" tabindex="-1" aria-labelledby="offcanvasMenuLabel">
    <div class="offcanvas-header align-items-start">
      <div>
        <h3 id="offcanvasMenuLabel">Menu</h3>
      </div>
      <button
        ref="close"
        type="button"
        class="btn-close"
        data-bs-dismiss="offcanvas"
        aria-label="Close"
        style="background: none; opacity: 1"
      >
        <img ref="close" aria-label="Close" src="/svg/iconsax/close-circle.svg" alt="" />
      </button>
    </div>
    <div class="offcanvas-body">
      <div class="nav nav-vertical">
        <div class="nav-item">
          <NuxtLink :to="{ name: 'index' }" class="nav-link ps-2" active-class="active" @click="close.click()">
            {{ $t('menus.header.home') }}
          </NuxtLink>
        </div>

        <div v-if="campaigns.length" class="nav-item">
          <NuxtLink
            :to="{ name: 'campaign', params: { campaign: campaigns[0].id } }"
            class="nav-link ps-2"
            active-class="active"
            @click="close.click()"
          >
            {{ $t('menus.header.surveys') }}
          </NuxtLink>
        </div>
        <!-- INÍCIO DA SIDEBAR -->
        <div class="sidebar-content">
          <div id="campaign-menu-items"></div>
        </div>
        <!-- FIM DA SIDEBAR-->
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
  import { useAuthUser } from '#imports';
  import { useDiagnosisStore } from '~/stores/diagnosisStore';

  const store = useDiagnosisStore();

  const campaigns = computed(() => store.campaigns);

  const close = ref(null);

  const { logout } = useAuth();

  const user = useAuthUser();
</script>
